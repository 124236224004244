var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.darkOverlay
        ? _c("div", {
            staticClass: "over-lay",
            on: {
              click: function ($event) {
                return _vm.showCalendar(false, true)
              },
            },
          })
        : _vm._e(),
      _c("FlInputWrapper", {
        attrs: {
          label: _vm.label,
          sublabel: _vm.sublabel,
          rules: _vm.rules,
          name: _vm.name,
          showError: _vm.showError,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "div",
                  { staticClass: "w-full relative h-10" },
                  [
                    _c("DatePicker", {
                      ref: "datePicker",
                      attrs: {
                        disabled: _vm.disabled,
                        inputClass:
                          errors.length && _vm.showError
                            ? "w-full p-2 h-10 border rounded bg-red-200 border-red-600"
                            : "w-full p-2 h-10 border rounded border-brown-grey disabled:bg-very-light-grey",
                        language: _vm.dateLanguage,
                        format: _vm.visualFormat,
                        "disabled-dates": {
                          to: _vm.fromDateDate,
                          from: _vm.toDateDate,
                        },
                      },
                      on: {
                        closed: function ($event) {
                          return _vm.showCalendar(false)
                        },
                      },
                      nativeOn: {
                        focusin: function ($event) {
                          return _vm.showCalendar(true)
                        },
                      },
                      model: {
                        value: _vm.mutableValue,
                        callback: function ($$v) {
                          _vm.mutableValue = $$v
                        },
                        expression: "mutableValue",
                      },
                    }),
                    _c("img", {
                      staticClass:
                        "w-6 h-6 absolute top-2 right-1 pointer-events-none",
                      attrs: { src: require("@/assets/image/calender.svg") },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }